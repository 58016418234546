<template>
    <section
        :class="{
            bordered: bordered,
            'p-4 mb-4': !collapsed,
            'pt-4 px-4 pb-0 mb-0': collapsed,
        }"
    >
        <div
            class="columns"
            :class="{ 'mb-0 pt-0': collapsed }"
        >
            <div class="column">
                <h6 class="is-size-6 has-text-weight-bold is-uppercase">
                    {{ title }}
                </h6>
            </div>

            <div
                v-if="$slots['actions']"
                class="column is-narrow"
            >
                <slot name="actions"></slot>
            </div>

            <div
                v-if="collapsable"
                class="column is-narrow"
            >
                <span
                    class="icon has-text-primary"
                    @click="collapsed = !collapsed"
                >
                    <i
                        class="fa-solid"
                        :class="{
                            'fa-circle-chevron-down': !collapsed,
                            'fa-circle-chevron-up': collapsed,
                        }"
                    ></i>
                </span>
            </div>
        </div>
        <hr
            v-if="collapsable && !collapsed"
            class="mt-0 pt-0"
        />

        <div v-show="!collapsed">
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
            required: true,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        collapsable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        collapsed: false,
    }),
}
</script>

<style scoped>
h6 {
    color: #ea621f;
}
.bordered {
    border: #ea621f solid 1px;
    padding-bottom: 2rem !important;
}

.icon {
    cursor: pointer;
}
</style>
